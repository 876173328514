<!--
 * @Descripttion: 404页面
 * @Author: zyr
 * @Date: 2021-05-30 13:36:49
 * @LastEditors: zyr
 * @LastEditTime: 2021-05-30 17:43:40
-->
<template>
  <div class="error-wrapper">
    <van-empty image="error" :description="description[type]"/>
  </div>
</template>
<script>
export default {
  name: 'Error',
  data () {
    return {
      description: [
        '啊哦，页面找不到',
        '查询不到人员!'
      ],
      type: 0
    }
  },
  beforeRouteEnter (to, from, next) {
    const { query: { type } } = to
    next(vm => { vm.type = +type || 0 })
  }
}
</script>
<style lang='less' scoped>
@import '../../assets/less/flex.less';
.error-wrapper{
  padding-top: .px2rem(300px)[@rem];
}
</style>
